// Components (.vue)
import CompanyLogo  from '@/Components/Global/CompanyLogo/template.vue'
import SubmitButton from '@/Components/Global/SubmitButton/template.vue'

// Constants
import { AppValues } from '@/Constants/AppValues'
import { Component } from '@/Constants/Component'
import { Server }    from '@/Constants/Server'
import { VueRouter } from '@/Constants/VueRouter'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const LoginForm = VueMixins(MixinComponent, MixinFetch, MixinResponsive).extend({
	name: 'LoginForm',

	mixins: [
		MixinComponent,
		MixinFetch
	],
	
	components: {
		CompanyLogo,
		SubmitButton
	},

	data: function() {
		return {
			inputs: {
				email: '',
				password: ''
			},
			states: {
				action: Component.Actions.INSERT,
				isFetching: false,
				rememberMe: false,
				visibility: false
			}
		}
	},

	mounted: function() {
		// Verificar si existen datos guardados en 'localStorage'.
		if (this._checkCredentials()) {
			const { email, password } = this._loadCredentials()
			this.inputs.email = email
			// this.inputs.password = password
			this.states.rememberMe = true
		}
	},

	computed: {
		_emailInputState: function(): boolean {
			const { email } = this.inputs
			if (email === '') return null
			return this._isValidEmailFormat(email)
		},

		_passwordInputState: function() {
			if (this.inputs.password.length === 0) {
				return null
			}
			else {
				return this.inputs.password.length > 0
			}
		}
	},

	methods: {
		_checkCredentials: function() {
			const { email, password } = this._loadCredentials()
			return email && password
		},

		_getSubmitButtonText: function() {
			const { isFetching } = this.states
			return isFetching ? 'Iniciando Sesión' : 'Ingresar'
		},

		_isValidEmailFormat: function(email: string) {
			return /^[a-z0-9]+(.+)@[a-z0-9]+\.[a-z0-9]+$/i.test(email)
		},

		_loadCredentials: function() {
			const email = localStorage.getItem(`${ AppValues.Strings.PREFIX_LOCAL_STORAGE }userEmail`)
			const password = localStorage.getItem(`${ AppValues.Strings.PREFIX_LOCAL_STORAGE }userPass`)
			return { email, password }
		},
		
		_saveCredentials: function(email: string, password: string) {
			const rMe = this.states.rememberMe
			localStorage.setItem(`${ AppValues.Strings.PREFIX_LOCAL_STORAGE }userEmail`, rMe ? email : '')
			localStorage.setItem(`${ AppValues.Strings.PREFIX_LOCAL_STORAGE }userPass`, rMe ? password : '')
		},

		onInputChange: function() {
			this.inputs.email = this.inputs.email.toLowerCase();
		},

		onLFResetPassword: function() {
			this.$router.push({ name: VueRouter.Views.Global.PASSWORD_RESET.NAME })
		},

		onServerCaughtFetchException: function() {
			this.setStates<LoginFormRef['states']>({ isFetching: false })
			this.$emit('onFailedLogin')
		},

		onServerFailedResponse: function() {
			this.setStates<LoginFormRef['states']>({ isFetching: false })
			this.$emit('onFailedLogin')
		},

		onSubmitClick: async function() {
			this.setStates<LoginFormRef['states']>({ isFetching: true })
			const { email, password } = this.inputs
			
			if (this._isValidEmailFormat(email) && password !== '') {
				// Emitir evento cuando el Correo sea valido y exista una clave ingresada.
				this.$emit('onLFButtonClick')

				// Generar la petición para validar a nivel de Servidor los datos ingresados.
				const accessToken = Buffer.from(`${ email }:${ password }`, 'utf8').toString('base64')

				// Cabezera de la Petición
				const headers = { 'Authorization': `Basic ${ accessToken }` }

				// Realizar la Petición para autenticar al usuario.
				const response = await this.fetchURL(Server.Fetching.Method.POST, Server.Routes.Commons.Login, null, headers)

				// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
				if (response.status === Server.Response.StatusCodes.SUCCESS) {
					const accessTokenFromResponse = { accessToken: response.data.access_token }
					
					// Si el CheckBox esta seleccionado, los datos serán guardados,
					// localmente, de lo contrario, serán borrados.
					this._saveCredentials(email, password)

					// Emitir el Evento de Inicio de Sesión exitoso.
					this.$emit('onSuccessLogin', accessTokenFromResponse, password)
				}
			}
			else {
				this.setStates<LoginFormRef['states']>({ isFetching: false })
				this.$emit('onFailedLogin')
			}
		}
	}
})

// Exports
export default LoginForm
export type LoginFormRef = InstanceType<typeof LoginForm>